<template>
  <div class="body-content">
    <Loader v-if="offer_loading" style="display: flex; align-items: center; justify-content: center" v-bind:logo="loaderLogo" ></Loader>
    <div class="row mb-10">
      <div class="col-xl-12 col-md-12 col-sm-12 mb-3 ddd">
        <p class="table-head last_refreshed" style="font-size: 23px;">
            <router-link to="/">
             <i class="fa fa-home mr-2" style="font-size: 30px; color: #000;"></i>
            </router-link>
     <span class="material-icons" style="margin-left: 0px !important;position: relative;top: 4px;margin-right: 9px;font-size: 21px;">arrow_forward_ios</span>Offers</p>
    	
        
      </div>
      <div class="col-xl-12 pt-0 profileInfo card o-body-container">
        <div class="offer-table pt-12 px-md-12 px-0">
			<!-- <div class="row mx-0">
				<div class="col-12">
					<div class="export-csv-btn">
						<label for="">Last upadated 3 hours ago</label>
						<button>Export CSV</button>
					</div>
				</div>
			</div> -->
			<!-- <span class="fa fa-download export_file mr-md-0 mr-2"  @click="DownloadFileAndFetch" ></span> -->
          <div class="tabs py-6 px-md-0 px-2 o-search-container">
             <div cols="6" class="text-capitalize text-left my-0 py-0">
              <p class="table-head table_res_head my-0 py-0">
               Offers Listing
                <br>
                <!-- <span>Updated 3 mins ago</span> -->
              </p>
            </div>
            <v-spacer class="table_res_head"></v-spacer>
            <div class="d-flex">
                <b-button v-if="selected.length > 0" class="o-check-request-btn" @click="handleMultipleRequest(selected)">REQUEST COUPON</b-button>
                <b-alert
                    v-model="showRequestToast"
                    class="position-fixed fixed-bottom m-0 rounded-0 o-toast-request d-flex align-items-center"
                    style="z-index: 2000;"
                    variant="warning"
                    dismissible
                    >
                    <div class="o-check-container mr-2">
                        <v-icon class="o-check-icon" dark>mdi-check</v-icon>
                    </div>
                    Your requested a tracker Successfully
                </b-alert>
                <b-alert
                    v-model="showRequestToastLimit"
                    class="position-fixed fixed-bottom m-0 rounded-0 o-toast-request-limit d-flex align-items-center"
                    style="z-index: 2000;"
                    variant="warning"
                    dismissible
                    >
                    <div class="o-check-container-limit mr-2">
                        <v-icon class="o-check-icon-limit" dark>mdi-check</v-icon>
                    </div>
                    You can request up to 3 trackers only
                </b-alert>
                <div class="d-flex search-container">
                    <b-input class="o-search-bar" placeholder="Search"  @input="e=>handleOnSearch(e)"></b-input>                
                    <v-icon dark class="search-icon">mdi-magnify</v-icon>
                </div>    
            </div>
          </div>
              <!-- <div class="data_grid mt-10 o-data-grid" v-if="offersData && offersData.length > 0">
                <table class="table table-responsive vue_data_grid mt-12 i-datatable">
                <thead style="width: 100%">
                    <th style="width: 15%;" class="light_gray">OFFER</th>
                    <th style="width: 12%" class="light_gray">PAYOUT</th>
                    <th style="width: 12%" class="light_gray">GEOS</th>
                    <th style="width: 15%" class="light_gray">COUPON OFFERING</th>                    
                    <th style="width: 12%" class="light_gray">STATUS</th>                    
                    <th style="width: 25%" class="light_gray">WEBSITE</th>                    
                    <th style="width: 10%" class="light_gray">CATEGORY</th>                    
                    <th style="width: 25%" class="light_gray"></th>                    
                  </thead>
                  <tbody>
                        <template v-for="(row, is) in offersData">
                          <template v-if="row.multipleData.length > 1">
                            <tr style="width: 100%" class="couponClass o-offer-row" :key="is">
                                <td style="width: 15%;text-align:left !important;"><a @click="row.showData = true" v-if="!row.showData" style="color:#000;display: inline-block;margin-left: 10px;cursor: pointer;white-space: nowrap;">{{ row.client }}<i class="fa fa-caret-down ml-3" aria-hidden="true" style="color:#000;"></i></a><a @click="row.showData = false" v-if="row.showData" style="color:#000;display: inline-block;margin-left: 10px;cursor: pointer;white-space: nowrap;">{{ row.client }}<i class="fa fa-caret-up ml-3" aria-hidden="true" style="color:#000;"></i></a></td>
                                <td style="width: 12%" v-if="!row.showData">{{ row.payout }}</td>
                                <td style="width: 12%" v-if="!row.showData">{{ row.geos }}</td>
                                <td style="width: 15%" v-if="!row.showData">-</td>
                                <td style="width: 10%" v-if="!row.showData">
                                     <div  v-if="row.status == 'Active'">
                                        <label for="" class="active_status text-nowrap" >Active</label>
                                    </div>
                                    <div  v-if="row.status == 'inactive'">
                                        <label for="" class="rejected_status text-nowrap">In-Active</label>
                                    </div>
                                    <div  v-if="row.status == 'paused'">
                                        <label for="" class="completed_status text-nowrap">Paused</label>
                                    </div>
                                    <div  v-if="row.status == 'pending'">
                                        <label for="" class="await_status text-nowrap">Pending</label>
                                    </div>
                                    <div  v-if="row.status == 'deleted'">
                                        <label for="" class="expired_status text-nowrap">Deleted</label>
                                    </div>
                                </td>
                                <td style="width: 25%; cursor: pointer; color: #0069c2; font-weight: 500;" v-if="!row.showData">{{ row.website }}</td>
                                <td style="width: 10%" v-if="!row.showData">-</td>
                                <td style="width: 25%" v-if="!row.showData">
                                    <div  v-if="selected.length === 0 && row.request_tracker_status == 'pending'">
                                        <label for="" class="text-nowrap" >Waiting for approval</label>
                                    </div>
                                    <div  v-if="selected.length === 0 && row.request_tracker_status == 'approve'">
                                        <label for="" class="text-nowrap" >Request Approved</label>
                                    </div>
                                    <div  v-if="selected.length === 0 && row.request_tracker_status == 'reject'">
                                        <label for="" class="text-nowrap" >Request Rejected</label>
                                    </div>
                                    <b-button v-if="selected.length === 0 && row.request_tracker_status == 'N/A'" class="o-request-btn" @click="handleRequestTracker(row)">Request Coupon</b-button>
                                </td>                               
                            </tr>
                            <tr class="couponClass o-offer-row" style="width: 100%;" v-for="(subSum, ind) in row.multipleData" v-if="row.showData">
                              <td class="advertise" style="width: 20%" >
                                    <v-checkbox
                                        v-if="subSum.request_tracker_status == 'N/A'"
                                        @change="toggleCheckbox(subSum)" 
                                        :input-value="handleSelectedCheckBox(subSum)"
                                        :key="ind"
                                    ></v-checkbox>
                                    <div class="ml-2" v-else>-</div>
                                </td>
                              <td style="width: 15%">{{ subSum.payout }}</td>
                              <td style="width: 15%">{{ subSum.geos }}</td>
                              <td style="width: 20%">{{ subSum.coupon_offering }}</td>
                              <td>
                                    <div  v-if="subSum.status == 'Active'">
                                        <label for="" class="active_status text-nowrap" >Active</label>
                                    </div>
                                    <div  v-if="subSum.status == 'inactive'">
                                        <label for="" class="rejected_status text-nowrap">In-Active</label>
                                    </div>
                                    <div  v-if="subSum.status == 'paused'">
                                        <label for="" class="completed_status text-nowrap">Paused</label>
                                    </div>
                                    <div  v-if="subSum.status == 'pending'">
                                        <label for="" class="await_status text-nowrap">Pending</label>
                                    </div>
                                    <div  v-if="subSum.status == 'deleted'">
                                        <label for="" class="expired_status text-nowrap">Deleted</label>
                                    </div>

                                </td>
                              <td style="width: 25%; cursor: pointer; color: #0069c2; font-weight: 500;" @click="handleOnOfferWebsite(subSum.website)">{{ subSum.website }}</td>
                              <td style="width: 25%; cursor: pointer; color: #0069c2; font-weight: 500;" @click="handleOnOfferWebsite(subSum.website)">View Website</td>
                              <td style="width: 22%">{{ subSum.category }}</td>
                              <td class="i-other-icon">
                                    <div  v-if="selected.length === 0 && subSum.request_tracker_status == 'pending'">
                                        <label for="" class="text-nowrap" >Waiting for approval</label>
                                    </div>
                                    <div  v-if="selected.length === 0 && subSum.request_tracker_status == 'approve'">
                                        <label for="" class="text-nowrap" >Request Approved</label>
                                    </div>
                                    <div  v-if="selected.length === 0 && subSum.request_tracker_status == 'reject'">
                                        <label for="" class="text-nowrap" >Request Rejected</label>
                                    </div>
                                    <b-button v-if="selected.length === 0 && subSum.request_tracker_status == 'N/A'" class="o-request-btn" @click="handleRequestTracker(subSum)">Request Coupon</b-button>
                                </td>
                            </tr>
                          </template>
                          <template v-else>
                          </template>
                        </template>
                        </tbody>
                        </table>
        </div>
        <div v-else class="d-flex mt-3 justify-content-center alert alert-primary" style="width: 100%;text-align: center;">
            <b>No Records</b>
        </div> -->
         <div class="table-div mt-5" v-if="offersData && offersData.length > 0">
                <v-data-table
                    class="table table-bordered i-datatable"
                    fixed-header
                    :headers="offer_columns"
                    :items="offersData"
                    :mobile-breakpoint="0"
                    v-model="selected"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr class="couponClass o-offer-row">
                            <td class="advertise" style="width:5%;">
                               <v-checkbox
                                    v-if="item.request_tracker_status == 'N/A'"
                                    @change="toggleCheckbox(item)" 
                                    :input-value="handleSelectedCheckBox(item)"
                                ></v-checkbox>
                            </td>
                            <td class="advertise" style="width: 10%">{{ item.client }}</td>
                            <td class="advertise" style="white-space: break-spaces; width: 25%">
                                <div v-html="item.payout_geos">
                                </div>
                            </td>
                            <td class="advertise" style="white-space: break-spaces; width: 15%">{{ item.geotargets }}</td>
                            <!-- <td class="advertise" >{{ item.geos }}</td> -->
                            <!-- <td class="o-fixed-td">{{ item.coupon_offering }}</td> -->
                            <td style="white-space: break-spaces; width: 10%" >
                                <div  v-if="item.status == 'Active'">
                                    <label for="" class="active_status text-nowrap" >Active</label>
                                </div>
                                <div  v-else-if="item.status == 'Inactive'">
                                    <label for="" class="rejected_status text-nowrap">In-Active</label>
                                </div>
                                <div  v-else>
                                    <label for="" class="">N/A</label>
                                </div>
                                <!--<div  v-if="item.status == 'Paused'">-->
                                    <!--<label for="" class="completed_status text-nowrap">Paused</label>-->
                                <!--</div>-->
                                <!--<div  v-if="item.status == 'pending'">-->
                                    <!--<label for="" class="await_status text-nowrap">Pending</label>-->
                                <!--</div>-->
                                <!--<div  v-if="item.status == 'deleted'">-->
                                    <!--<label for="" class="expired_status text-nowrap">Deleted</label>-->
                                <!--</div>-->
                            </td>
                            <!-- <td class="i-action o-fixed-td">{{ item.website }}</td> -->
                            <!-- <td class="i-action o-fixed-td" style="cursor: pointer; color: #0069c2; font-weight: 500;" @click="handleOnOfferWebsite(item.website)">View Website</td> -->
                            <!-- <td class="i-hover">{{ item.category }}</td> -->
                            <!-- <td class="i-other-icon text-center">
                                <div  v-if="item.request_tracker_status == 'pending'">
                                    <label for="" class="text-nowrap" >Waiting for approval</label>
                                </div>
                                <div  v-if="item.request_tracker_status == 'approve'">
                                    <label for="" class="text-nowrap" >Request Approved</label>
                                </div>
                                <div  v-if="item.request_tracker_status == 'reject'">
                                    <label for="" class="text-nowrap" >Request Rejected</label>
                                </div>
                            </td> -->
                            <!-- <b-button v-if="selected.length === 0 && item.request_tracker_status == 'N/A'" class="o-request-btn" @click="handleRequestTracker(item)">Request Coupon</b-button> -->
                        </tr>
                    </template>
                    </v-data-table>
                </div>
                <div class="d-flex mt-3 justify-content-center alert alert-primary" style="width: 100%;text-align: center;" v-if="!offersData || (offersData&&offersData.length==0)">
                    <b>No Records</b>
                </div>
      </div>
	</div>
  </div>
  </div>
</template>
<script>
import Vue from "vue";
import ApiService from "../../core/services/api.service";
import { mapGetters } from "vuex";
import Loader from "@/view/content/Loader.vue";
import Swal from "sweetalert2";
export default {
  name: "offers",
  components: { ApiService,Loader },
  data() {
    return {
      showRequestToast: false,
      showRequestToastLimit: false,
      offer_loading: false,
      merchantTotal: 0,
      selected: [],
      selectedOffer: [],
      offersData: [],
      offersDataOld: [],
      offer_columns: [
                 {
                    text: "",
                    value: "",
                    align: "center",
                    sortable: false,
                },
                 {
                    text: "OFFER",
                    value: "client",
                    align: "center",
                    sortable: false,
                },
                 {
                    text: "PAYOUTS",
                    value: "payout_geos",
                    align: "center",
                    sortable: false,
                },
                 {
                    text: "GEOS Targets",
                    value: "geotargets",
                    align: "center",
                    sortable: false,
                },
                //  {
                //     text: "GEOS",
                //     value: "geos",
                //     align: "center",
                //     sortable: false,
                // },
                //  {
                //     text: "COUPON OFFERING",
                //     value: "coupon_offering",
                //     align: "center",
                //     sortable: false,
                // },
                 {
                    text: "STATUS",
                    value: "status",
                    align: "center",
                    sortable: true,
                },
                //  {
                //     text: "WEBSITE",
                //     value: "website",
                //     align: "center",
                //     sortable: false,
                // },
                //  {
                //     text: "CATEGORY",
                //     value: "category",
                //     align: "center",
                //     sortable: false,
                // },
                //  {
                //     text: "",
                //     value: "",
                //     align: "center",
                //     sortable: false,
                // },
            ]
    };
  },  
  mounted() {
    this.affiliate_id = window.localStorage.getItem("affiliate_id");
    this.get_offers();    
    this.offer_loading = true;
    this.$eventBus.$on("offerFilter", (data) => {
        this.offerDataFilter(data);
    });    
    this.$eventBus.$on("downloadFile", () => {
        this.exportTableToCSV("Offer List.csv");
    });    
    setInterval(() => {
      this.$eventBus.$emit('setNotification');
    }, 60000)
  },
  methods: {    
      handleOnOfferWebsite(url){        
        window.open(url,"_blank")
      },
      offerDataFilter(data){
        this.offersData = [];
        this.offersData = this.offersDataOld;
        // this.formatOfferData(this.offersData);
        if(data == "alphabet"){
            this.offersData = this.offersData.sort((a, b) => a.client.localeCompare(b.client));
        }
        if(data == "status"){
            this.offersData = this.offersData.sort((a, b) => a.status.localeCompare(b.status));
        }
        console.log(this.offersData,"offersData");
        // if(data == "coupon"){
        //     this.offersData = this.offersData.sort((a, b) => a.coupon_offering.localeCompare(b.coupon_offering));
        // }
        // this.formatOfferData(this.offersData);
      },
      handleOnSearch(data){
        this.offersData = [];
        this.offersData = this.offersDataOld;
        if(data !== ""){
            this.offersData = this.offersData.filter((item) => item.client?.toLowerCase().includes(data.toLowerCase()) || item.payout_geos?.toLowerCase().includes(data.toLowerCase()) || item.status?.toLowerCase().includes(data.toLowerCase()) || item.geotargets?.toLowerCase().includes(data.toLowerCase()))
            // this.formatOfferData(this.offersData);
        } else { 
            this.offersData = this.offersDataOld;
            // this.formatOfferData(this.offersData);
        }
      },
      get_offers(){   
        this.offersData = [];     
        this.offersDataOld = [];     
        ApiService.post("/offer/list", {
          affiliate_id: this.affiliate_id,
        }).then((response) => {      
          let data = response.data.data.offers;
          this.offer_loading = false;
          Object.keys(data).forEach(key => {
            // const payout = data[key]
            this.offersData = [...this.offersData, {...data[key]}]
            this.offersDataOld = [...this.offersDataOld, {...data[key]}]
          });
            // this.formatOfferData(this.offersData);
        }).catch(error => {
            if(error.response.status == 400){
                localStorage.clear();
                localStorage.removeItem("affiliate_id");
                window.location.reload();
                // Swal.fire({
                //   imageUrl: "media/images/logout-emoji.png",
                //   imageWidth: 165,
                //   imageHeight: 123,
                //   title: "Your Session is expired.",
                //   timer: 1500,
                //   timerProgressBar: true,
                //   showConfirmButton: false,
                // })
                // setTimeout(() => {
                //   window.location.reload();
                // }, 1500);
            }
            throw new Error(`[KT] ApiService ${error}`);
        });
      },
        exportTableToCSV(filename) {
            var csv = [];
            var rows = this.offersData;
            for (var i = 0; i < rows.length; i++) {
                let payout_geos = rows[i].payout_geos.replaceAll(',',' -');
                let geotargets = rows[i].geotargets.replaceAll(',',' -');
                let cols = rows[i].client +','+ payout_geos +','+ geotargets +','+ rows[i].status +','+ rows[i].website;
                csv.push(cols);
            }
            csv = csv.join("\n");
            this.downloadCSV(csv, filename);
        },
        downloadCSV(csv, filename) {
            var csvFile;
            var downloadLink;
            csvFile = new Blob([csv], { type: "text/pdf" });
            downloadLink = document.createElement("a");
            downloadLink.download = filename;
            downloadLink.href = window.URL.createObjectURL(csvFile);
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
        },
      formatOfferData(data){
          this.offersData = [];
          let uniqueOffersData = Object.values(data.reduce((r, o) => {
              r[o.offer_id] = r[o.offer_id] || o;
                return r;
          },{}));
          this.offersData  = uniqueOffersData.map((item,i) => {
              return Object.assign({}, item, {
                showData:true,
                multipleData: data.filter(function (curr) {if (curr.offer_id === item.offer_id) { return curr }}),
                payout :  data.filter(function (curr) {if (curr.offer_id === item.offer_id) { return curr }})[0].payout,
                geos :  data.filter(function (curr) {if (curr.offer_id === item.offer_id) { return curr }})[0].geos,
                coupon_offering :  data.filter(function (curr) {if (curr.offer_id === item.offer_id) { return curr }})[0].coupon_offering,
                status :  data.filter(function (curr) {if (curr.offer_id === item.offer_id) { return curr }})[0].status,
                website :  data.filter(function (curr) {if (curr.offer_id === item.offer_id) { return curr }})[0].website,
                category :  data.filter(function (curr) {if (curr.offer_id === item.offer_id) { return curr }})[0].category,
              })
          });
        //   console.log(this.offersData,"offerdata");
      },
      handleRequestTracker(item){
        this.offer_loading = true;
        let singleData = []       
        let data = {          
            affiliate_id: this.affiliate_id,
            id: item.id,
            offer_id: item.offer_id,
            client_id: item.client_id,
            coupon_code: item.coupon,
            coupon_description: item.coupon_offering,
            payout: item.payout_geos.replace(/<\/?[^>]+>/gi,' '),
        }  
        singleData.push(data);
        ApiService.post("/request/coupon", singleData).then((response) => {             
           this.offersData = [];
           if(response.data.result == true){
                this.offer_loading = false;
                Swal.fire({
                    title: "Thank you for your request we will contact you shortly",
                    imageUrl: "media/images/successCircle.png",
                    imageWidth: 165,
                    imageHeight: 123,
                    imageAlt: "Success",
                    showCancelButton: true,
                    confirmButtonText: "Ok, Go Back",
                    cancelButtonText: "&#215;",
                    confirmButtonColor: "#FF6633",
                    cancelButtonColor: "#FF6633",
                    reverseButtons: true,
                    customClass: {
                        container: 'request-container-class',
                        popup: 'request-popup-class',
                        header: 'request-header-class',
                        title: 'request-title-class',
                        content: 'request-content-class',
                        actions: 'request-confirm-class'
                        }
                }).then((result) => {
                }); 
                this.get_offers();
            }
        }).catch(error => {
            if(error.response.status == 400){
            localStorage.clear();
            localStorage.removeItem("affiliate_id");
            window.location.reload();
            // Swal.fire({
            //   imageUrl: "media/images/logout-emoji.png",
            //   imageWidth: 165,
            //   imageHeight: 123,
            //   title: "Your Session is expired.",
            //   timer: 1500,
            //   timerProgressBar: true,
            //   showConfirmButton: false,
            // })
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1500);
            }
            throw new Error(`[KT] ApiService ${error}`);
        });
        
    },
    handleMultipleRequest(selected){
        if(selected.length <= 3){
            this.offer_loading = true;
            this.offersData = [];
            this.selected = [];
            ApiService.post("/request/coupon",this.selectedOffer)
            .then((response) => {  
                if(response.data.result == true){
                this.offer_loading = false;
                    this.showRequestToast = !this.showRequestToast;
                    setTimeout(() => {
                        this.showRequestToast = !this.showRequestToast;
                    }, 3000); 
                    this.get_offers();
                }
            }).catch(error => {
                if(error.response.status == 400){
                    localStorage.clear();
                    localStorage.removeItem("affiliate_id");
                    window.location.reload();
                    // Swal.fire({
                    //   imageUrl: "media/images/logout-emoji.png",
                    //   imageWidth: 165,
                    //   imageHeight: 123,
                    //   title: "Your Session is expired.",
                    //   timer: 1500,
                    //   timerProgressBar: true,
                    //   showConfirmButton: false,
                    // })
                    // setTimeout(() => {
                    //   window.location.reload();
                    // }, 1500);
                }
                throw new Error(`[KT] ApiService ${error}`);
            });
        } else {
            this.showRequestToastLimit = !this.showRequestToastLimit;
            setTimeout(() => {
                this.showRequestToastLimit = !this.showRequestToastLimit;
            }, 3000);
        }
    },
    toggleCheckbox(item) {
      const idx = this.selected.indexOf(item);    
      let data = {          
        affiliate_id: this.affiliate_id,
        id: item.id,
        offer_id: item.offer_id,
        client_id: item.client_id,
        coupon_code: item.coupon,
        coupon_description: item.coupon_offering,
        payout: item.payout_geos.replace(/<\/?[^>]+>/gi,' '),
      }  
      if(idx === -1) {
        this.selected.push(item);
        this.selectedOffer.push(data);
      }  
      else {
        this.selected.splice(idx,1);
        this.selectedOffer.splice(idx,1);
      }  
    },
    handleSelectedCheckBox(item){
        let data1 = this.selected.map(item1=>{
            return item1.id;
        })
        return data1.indexOf(item.id) !== -1 ? 1 : 0;
    }
  },  
  computed: {
    ...mapGetters(["layoutConfig"]),
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
};
</script>

<style lang="scss">
.o-data-grid .table-responsive{
    display: block !important;
}
.o-data-grid .vue_data_grid{
    height: 40rem !important;
    background: #fff !important;
}
.notify-popup-list{
  color: #3F4254 !important;
}
.notify-confirm-class{
  margin: 0px !important;
}
.notify-confirm-class .swal2-confirm.swal2-styled{
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
}
.notify-confirm-class .swal2-cancel.swal2-styled{
    padding: 0px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: transparent !important;
    position: absolute;
    top: 13px;
    right: 10px;
    margin-left: 15px;
    color: #000 !important;
    border-radius: 15px !important;
    font-size: 25px;
    font-weight: 600;
    width: 15px;
    height: 15px;
}
.h-notify-count{
  background: #f56160;
  color: #fff;
  border-radius: 10px;
  width: 25px;
  font-size: 12px;
}
.notify-container-class{
  display: flex;
  z-index: 1 !important;
  background: rgba(0,0,0,.5) !important;
}
.notify-popup-class{
  padding: 0px !important;
  z-index: 99999999999 !important;
      border-radius: 0.3125em;
    overflow: hidden;
}
.notify-header-class{
  display: block !important;
  padding: 20px !important;
  background: #ebebeb !important;
  border-bottom: 1px solid #121212;
}
.notify-title-class{
  margin: 0px!important;
  color: #5f5c62 !important;
  font-weight: 600 !important;
  font-size: 1.5rem;
}
.notify-content-class{
  margin: 0px !important;
  padding: 0px !important;
  text-align: left;
}
.notify-message-class{
  padding: 20px !important;
  border-bottom: 1px solid #f4f5f8;
  text-align: left !important;
}
.notify-message-class .bold{
  font-weight: 600;
}
.btn-mobile-profile ul{
  width: auto !important;
}
.o-body-container{
    z-index: unset !important;
}
.search-container{
    position: relative;
}
.search-icon{
    color: #151a4b !important;
    position: absolute !important;
    right: 5px;
    top: 8px;
}
.offer-container{
    text-align: center !important;
    min-width: fit-content !important;
}
.offer-container .dropdown-item:active{
  background-color: #EBEDF3 !important;
}
.offer-link:hover{
  background-color: transparent !important;
}
.offer-sort-btn {
    font-size: 13px !important;
    font-weight: normal !important;
    line-height: normal !important;
    color: inherit !important;
    background-color: transparent !important;
    span {
        font-size: 15px;
    }
}
.offer-text{
    margin-right: 10px !important;
}
.offer-link{
    padding: 0.25rem 0.25rem !important;
}
.sort-offer-link{
    padding: 0.75rem 0.5rem !important;
}
.offer-link:hover{
    color: #0f2753 !important;
}
.offer-head{
    margin-right: 10px !important;
    white-space: nowrap !important;
    font-weight: 700 !important;
    color: #0f2753 !important;
    font-size: 15px;
}
.o-check-container{
    padding: 7px;
    background-color: #4aae8c !important;
    border-radius: 10px !important;
}
.o-check-container-limit{
    padding: 7px;
    background-color: #db6a92 !important;
    border-radius: 10px !important;
}
.o-check-icon{
    color: #4aae8c !important;
    background-color: #ffffff !important;
    border-radius: 15px !important;
}
.o-check-icon-limit{
    color: #db6a92 !important;
    background-color: #ffffff !important;
    border-radius: 15px !important;
}
.o-toast-request{
    right: 120px !important;
    left: unset !important;
    bottom: 25px !important;
    width: fit-content !important;
    background-color: #deffee !important;
    border-radius: 7px !important;
    border: 2px solid #b7fce7 !important;
    color: #2d3748 !important;
    padding: 8px 55px 8px 8px !important;
}
.o-toast-request .close{
    top: 8px !important;
}
.o-toast-request-limit{
    right: 120px !important;
    left: unset !important;
    bottom: 25px !important;
    width: fit-content !important;
    background-color: #ffd6dd !important;
    border-radius: 7px !important;
    border: 2px solid #db6a92 !important;
    color: #2d3748 !important;
    padding: 8px 55px 8px 8px !important;
}
.o-toast-request-limit .close{
    top: 8px !important;
}
.o-check-request-btn{
    margin-top: 0px !important;
    width: auto !important;
    height: fit-content !important;
    border-radius: 6px !important;
    color: #000 !important;
    border-color: #FF6633 !important;
    background-color: #FF6633 !important;
    white-space: nowrap;
    padding: 0.65rem 1rem;
}
.o-search-bar{
    padding-right: 30px !important;
    background: #FFF2F2;
    border: none;
    border-radius: 5px;
}
.o-search-bar:focus{    
    background: #FFF2F2;
}
.o-check-box .custom-control-label::before{
 border-radius: 3px !important;
}
.o-request-btn{
    padding: 5px 12px !important; 
    white-space: nowrap !important;
    border-radius: 8px !important;
    border: 2px solid #5d7bb1 !important;
    background: transparent !important;
    color: #5d7bb1 !important;
}
.o-offer-row{
    border-bottom: 5px solid #FFF2F2;
}
.o-offer-row .v-input__slot{
    margin-bottom: 0px !important;
}
.o-offer-row .v-messages{
    min-height: 0px !important;
}
.o-offer-row .v-input--selection-controls{
    margin-top: 0px !important;
}
.i-datatable > .v-data-table__wrapper > table > thead > tr:last-child > th, .i-datatable > thead > th{
    text-align: left !important;
    font-size: 12px !important;
    font-weight: normal;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: #FFF2F2;
    color: #b5b5b5;
    white-space: nowrap;
    z-index: 0;
}
.i-datatable > .v-data-table__wrapper > table > tbody > tr > td{
    text-align: left !important;
    font-size: 13px !important;
    font-weight: 700;
    white-space: nowrap;
}
.col-xl-12.profileInfo.pt-0 {
    margin-top: 0px;
    position: relative;
    z-index: 99;
}
.btn-div{
	display:flex;
	align-items: center;
	justify-content: space-between;
	.update-btn{}
	.export-btn{
		button{
			background: linear-gradient(rgb(3, 3, 53),rgb(11, 28, 102));
			border:none;
			border-radius: 6px;
			font-size:13px;
			color:#fff;
			padding:7px 15px;
		}
	}

}
.invoiceLabels {width: 30%;}
.invoiceLabels ul li {
    padding: 7.5px 7px;
    margin-right: 5px;
    cursor: pointer;
    border-top: 2px solid #949494;
    transition: 0.2s ease-in-out;
}
.invoiceLabels ul li:last-child {
    border-bottom: 2px solid #949494;
}
.invoiceLabels ul li:hover {
    background: #d5d5d5;
}
.invoiceLabels ul li.active{
  background: #383b5d;
  color: #fff;
}
.container-fluid{
	padding:0px !important;
}

.table-div{
  // max-height: 420px;
  // overflow: hidden;
  // overflow-y:auto;
  table{
	  thead{
		  padding:0px;
	  }
    tbody{
      tr{
        .long-column-bottom{
          min-width: 180px;
          font-weight: bold;
          vertical-align: middle;
        }
        .long-column{
          min-width: 180px;
          // vertical-align: bottom;
          h3{
            font-weight: bold;
            margin: 0px;
            font-size: 19px;
          }
        }
        td{
			
        }
      }
    }
  }
}
@media screen and (max-width: 768) {
	 .table {
		 border: none;
	}
}
@media screen and (max-width: 767.9px) {    
    #kt_content > .d-flex.flex-column-fluid {
        position: relative;
        top: 0px !important;
        margin-bottom: 100px;
    }
    .o-search-container{
        margin: unset !important;
        margin-left: auto !important;
    }
	.table thead th {
		padding:5px 10px;
		
	 }
	.table thead th span {
		font-size:10px;
		white-space:nowrap;
	 }
	 .table tbody tr td{
		 word-break: break-all;
	 }
}
 @media screen and (max-width: 768) {
	 .table tr {
		 border-bottom: 3px solid #ddd;
		 display: block;
		 margin-bottom: 0.625em;
	}
}
 @media screen and (max-width: 768) {
	 .table thead {
		 border: none;
		 clip: rect(0 0 0 0);
		 height: 1px;
		 margin: -1px;
		 overflow: hidden;
		 padding: 0;
		 position: absolute;
		 width: 1px;
	}
}
 @media only screen and (max-width: 768px) {
   .table-coupon thead{
     display: none;
   }
   .table-coupon tr{
     border-bottom: 15px solid #f0f4f7;
   }
	 .table-coupon td {
		 border-bottom: 1px solid #ddd;
		 display: flex;
     display: -webkit-flex;
     display: -ms-flexbox;
		 font-size: 0.8em;
		 text-align: right;
	}
	 .table-coupon td::before {
		 content: attr(data-label);
		 display: block;
		 width: 100%;
		 text-align: left;
		 font-weight: bold;
		 text-transform: uppercase;
	}
	 .table-coupon td:last-child {
		 border-bottom: 0;
	}    
    #kt_content{
        min-height: 100vh !important;
        padding-top: 0px;
        height:  unset !important;
    }
}
.body-content{
  position: unset !important;
  width:100%;
}
#kt_content{
  min-height: 100vh !important;
  padding-top: 50px;
  height:  unset !important;
}
.crone-alert{
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 5px 5px 5px 10px;
  border-radius: 5px;
  margin: 10px 0px;
  font-weight: 600;
}
.filterButtonGroup {
  display: flex;
  width: 100%;
}
.filterButtonGroup button {
  margin-right: 5px;
}
.badge {
  color: white;
  border-radius: 5px;
}
.new-tag-bg {
  background: #0253b0;
  border-color: #0253b0;
}
.comfirmed-tag-bg,
.approved-tag-bg {
  background: #00c778;
  border-color: #00c778;
}
.cancelled-tag-bg {
  background: #707070;
  border-color: #707070;
}
.export_file {
  float: right;
  cursor: pointer;
}
.s_date {
  // margin-right: 4%;
  cursor: pointer;
}
.select {
  color: #000;
  font-weight: 700;
}
//   .plan-boxes {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }

// @media only screen and (max-width: 600px) {
//   .plan-box {
//     height: 40%;
//     position: relative;
//   }
//   .logo{
//     width: 80% !important;
//   }
// }
.modal-btn {
  width: 100%;
  color: white;
  background: #000;
  border-radius: 0px;
}
.tabs {
  display: flex;
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid #e8e1e1;
  .tab {
    cursor: pointer;
  }
  .active {
    border-bottom: 1.5px solid black;
  }
}
@media only screen and (max-width: 450px) {
  .tabs {
    display: table !important;
    margin: auto;
  }
}

// for destop
@media only screen and (min-width: 1200px) {
  .plan-boxes {
    span:first-of-type {
      margin-bottom: 7%;
    }
  }
  .plan-box {
    background-color: #ffffff;
    min-height: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    span:first-of-type {
      display: block;
      text-transform: uppercase;
      color: #000;
      font-weight: bold;
      font-size: 16px;
    }

    h1 {
      font-weight: bold;
      color: #000;
      font-size: 4rem;
      margin: 10px 0;
    }

    span:last-of-type {
      display: block;
      text-transform: capitalize;
      color: #000;
      font-weight: bold;
      font-size: 16px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .last_refreshed {
    color: black !important;
    // margin-top: 220px;
    icon {
      color: white;
    }
    span {
      margin-left: 1rem;
    }
  }
  .o-fixed-td{
      white-space: unset !important;
  }
  .table-div.referralDetails + div.table-div table thead th:last-child {
    text-align: center !important;
    padding-right: 70px;
}

.table-div.referralDetails + div.table-div table tbody td:last-child {
    text-align: center !important;
    padding-right: 70px;
}
}
.ddd {
    z-index: 0;
  margin-top: -2.5rem !important;
}
@media only screen and (max-width: 1200px) {
  .last_refreshed {
    color: #19248d;
    margin-top: 0px !important;
    span {
      margin-left: 1rem;
    }
  }
}
@media only screen and (max-width: 1600px) {
  .last_seen {
    color: white;
    float: right;
    span {
      margin-left: 1rem;
    }
  }
}

//for screens bigger then mobile
@media only screen and (min-width: 1600px) {
  .last_seen {
    color: black;
    float: right;
    span {
      margin-left: 1rem;
    }
  }
}

// for mobile
@media only screen and (max-width: 1200px) {
  .offer-text{
      margin-right: 0px !important;
  }
  .plan-boxes {
    margin-bottom: 5%;
    display: inline;
    span:last-of-type {
      float: right;
    }
  }
  .plan-box {
    background-color: #ffffff;
    width: 45%;
    min-height: 100px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    span:first-of-type {
      display: block;
      text-transform: uppercase;
      color: #000;
      font-weight: bold;
      font-size: 12px;
    }

    h1 {
      font-weight: bold;
      color: #000;
      font-size: 2rem;
      margin: 10px 0;
    }

    span:last-of-type {
      display: block;
      text-transform: capitalize;
      color: #000;
      font-weight: bold;
      font-size: 12px;
    }
  }
#kt_content .ddd {
    display: none;
}

.header-mobile-fixed .header-mobile {
    height: 350px !important;
}

#kt_content .container-fluid {
    margin-top: -240px;
    position: relative;
    // z-index: 99;
}

.plan-box span:last-of-type {
    font-size: 16px;
}

.plan-box h1 {
    font-size: 3rem;
}

.btn-div.mt-5.mobile {
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
    padding: 0 10px 0 0;
}
.btn-div.mt-5.mobile button.btn.btn-secondary {
    background: transparent;
    border: 0;
    margin: 0;
    width: auto;
}

.dropdown.mobile.d-lg-none.d-block.p-2 {
    display: none !important;
}
}



.light_gray {
  color: rgb(181, 181, 181);
  padding:0px;
}
.toggle_buttons {
  border: 2px solid rgb(121, 121, 121) !important;
  border-radius: 5px !important;
  font-size: 1rem !important;
  font-weight: bold !important;
}
@media only screen and (max-width: 600px) {
  .toggle_buttons {
    width: 6rem !important;
    font-size: 0.8rem !important;
  }
}
@media only screen and (max-width: 450px) {
  .toggle_buttons {
    width: 7rem !important;
    height: 4rem !important;
    font-size: 0.85rem !important;
    margin: 0.1rem !important;
  }
}
@media only screen and (max-width: 363px) {
  .toggle_buttons {
    width: 6rem !important;
    height: 2.5rem !important;
    font-size: 0.7rem !important;
  }
}
@media only screen and (max-width: 305px) {
  .toggle_buttons {
    width: 4rem !important;
    height: 3rem !important;
    font-size: 0.5rem !important;
  }
}
@media only screen and (max-width: 450px) {
  .table_res_head {
    display: none !important;
  }
}

#subparent .tab_buttons {
  border: none !important;
  border-radius: 5px !important;
  color: white;
  background-color: #FF6633 !important;
}
#parent .tab_buttons {
  border: none !important;
  border-radius: 5px !important;
  color: #000;
  background-color: #FF6633 !important;
}
.active_status, .completed_status {
    width: auto;
    text-align: center;
    line-height: normal;
    border-radius: 0;
    font-weight: 400;
    padding: 0 15px 1px;
    background: transparent;
    color: #3f4254;
    position: relative;
    padding-left: 15px!important;
    font-size: 13px!important;
}
.active_status:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 5px;
    left: 2px;
    background: #00c778;
    border-radius: 50%;
    box-shadow: 0 0 3px #00c778;
}

.expired_status:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 5px;
    left: 2px;
    background: red;
    border-radius: 50%;
    box-shadow: 0 0 3px red;
}
.rejected_status:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 5px;
    left: 2px;
    background: #a6b6d4;
    border-radius: 50%;
    box-shadow: 0 0 3px #a6b6d4;
}
.completed_status:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 5px;
    left: 2px;
    background: #68daf2;
    border-radius: 50%;
    box-shadow: 0 0 3px #68daf2;
}
.await_status:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 5px;
    left: 2px;
    background: #f6946f;
    border-radius: 50%;
    box-shadow: 0 0 3px #f6946f;
}
.status_coupon {
  color: rgb(163, 137, 137);
  border-radius: 0.3rem;
}
.modal-content{
  bottom: unset !important;
}

@media(max-width:767px){
  .bar span {
    font-size: 11px;
}  
}



// Changes

div#filter_modal___BV_modal_outer_ {
    z-index: 9999999 !important;
}

@media(min-width:1200px){

  p.last_refreshed {
    color: #000 !important;
    // margin-top: 110px !important;
    position: relative;
    z-index: 99;
    padding-left: 0;
    font-weight: inherit;
    font-size: 20px;
    margin-bottom: 8px;
    width: 70%;;
    margin-top:0 !important;
}

p.last_refreshed + .btn-div.mt-5 {
    position: relative;
    z-index: 999;
    padding-left: 0;
    margin-top: 0 !important;
    width: 70%;
}

p.last_refreshed + .btn-div.mt-5 .export-btn {
    display: none;
}

p.last_refreshed + .btn-div.mt-5 .update-btn button {
    font-size: 12px;
    padding: 6px 10px;
    border-radius: 6px;
}
.content-wrapper > div > .row {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 0 !important;
}

.content-wrapper .plan-boxes {
    max-width: none !important;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -93px;
    position: relative;
    width: 80%;
    margin-left: 20%;
    margin-bottom: 17px;
}

.content-wrapper .plan-boxes + div {
    max-width: 100% !important;
    flex: none;
    position: relative;
}

.plan-boxes span {
    min-height: auto;
    min-width: 140px;
    padding: 5px;
    margin-bottom: 0 !important;
}

.plan-box h1 {
    margin: 0;
    font-size: 30px;
}

span.plan-box:first-of-type {
    margin-right: 10px;
}

#kt_content > div > .container-fluid {
    max-width: 1185px;
    width: 100%;
}

.offer-table {
    padding-top: 0 !important;
}

.v-btn:before {
    display: none;
}

.v-btn-toggle > .v-btn.v-btn {
    opacity: 1 !important;
    height: 40px !important;
    border-width: 1px !important;
}

.offer-table p.table-head {
    font-size: 18px;
    color: #000;
}

.offer-table .tabs {
    border: 0;
}
.offer-table p.table-head span {
    font-size: 10px;
    position: relative;
    top: -10px;
    opacity: 0.3;
}
.data_grid {
    margin-top: 0 !important;
}
.table thead th, .table tbody td {
    border: 0 !important;
}

.table-div .table-bordered {
    border: 0 !important;
}
.modal .modal-header h4 {
    color: #000;
    font-weight: bold;
    font-size: 20px;
}

.modal .modal-header {
    border: 0;
    padding-bottom: 0;
}

div#filter_modal___BV_modal_body_ > .row > .col-12:first-child {
    color: #000;
    font-weight: bold;
}

div#filter_modal___BV_modal_body_ > .row label {
    font-weight: bold;
    color: #000;
}

div#filter_modal___BV_modal_body_ > .row > div input {
    color: #000;
}

.update-btn span {
    font-size: 11px;
    color: #000;
}

.update-btn span i {
    color: #000;
    font-size: 11px;
    margin-left: 5px;
    -webkit-transform: rotateY(160deg) rotate(12deg);
    transform: rotateY(160deg) rotate(12deg);
}
tr.offers {
    display: none;
}
.expired_status {
    padding: 0px 5px 1px;
    width: auto;
    text-align: center;
    color: #fff;
    line-height: normal;
    border-radius: 0;
    font-weight: normal;
}
.table thead th {
    text-align: left !important;
    font-size: 12px !important;
    font-weight: normal;
} 
td.for-desk {
    width: 200px;
}
td.advertise {
    width: 250px;
}
.data_grid table {
    border: 0;
}
.data_grid table tr td {
    font-weight: bold;
}

.content-wrapper .plan-boxes {
    margin-bottom: 29px;
}

// div#filter_modal___BV_modal_body_ > .row > div {
//     margin-bottom: 24px;
// }
.v-card.v-sheet.theme--light .v-list-item.theme--light {
    padding-left: 0;
}
p.last_refreshed + .btn-div.mt-5 .update-btn button {
    display: block !important;
    margin-top: 15px;
}

.update-btn {
    display: flex;
    flex-direction: column-reverse;
}
div#modal-1___BV_modal_outer_ {
    z-index: 9999999 !important;
}
.table-div:first-child > table > tbody > tr {
    border-bottom: 15px solid #fff!important;
    background-color: #f4f9ff;
}

#kt_content > .d-flex.flex-column-fluid {
    position: relative;
    top: 100px;

}
}




form.form .btn.btn-dark.form-control {
    margin-top: 20px;
}
.form-group label {
    font-size: 13px !important;
    font-weight: bold !important;
    color: #000 !important;
}

footer#filter_modal___BV_modal_footer_ .modal-btn.btn-dark {
    border-radius: 6px;
}

@media(max-width:1200px){
  #kt_content .ddd {
    display: none;
}

.header-mobile-fixed .header-mobile {
    height: 350px !important;
}

#kt_content .container-fluid {
    margin-top: -240px;
    position: relative;
    // z-index: 99;
}


.plan-boxes .plan-box {
    padding: 0 10px;
}

.plan-box span:last-of-type {
    font-size: 16px;
}

.plan-box h1 {
    font-size: 3rem;
}

.btn-div.mt-5.mobile {
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
    padding: 0 10px 0 0;
}
.btn-div.mt-5.mobile button.btn.btn-secondary {
    background: transparent;
    border: 0;
}

.dropdown.mobile.d-lg-none.d-block.p-2 {
    display: none !important;
}

.v-btn-toggle--group > .v-btn.v-btn {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    min-width: auto!important;
    width: 80px !important;
    font-size: 13px !important;
    display: block !important;
    margin-right: 18px !important;
    border-bottom: 2px solid transparent !important;
    margin-right: 6px !important;
    padding-bottom: 5px;
    color: #8080808c !important;
}

#parent .tab_buttons {
    color:#151a4b !important;
    background-color: transparent !important;
    min-width: auto!important;
    width: 80px !important;
    font-size: 13px !important;
    border-bottom: 2px solid #FF6633 !important;
    border-radius: 0 !important;
    font-weight: bold !important;
}

.v-btn:before {
    display: none;
}

.v-btn-toggle--group {
    display: flex !important;
    flex-wrap: nowrap !important;
    overflow-x: auto;
    width: 270px !important;
    padding: 0 0;
    padding-bottom: 5px;
}

.v-btn__content {
    display: inline-block;
}

.modal-content {
    bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.data_grid .alert.alert-primary {
    width: 90%;
    margin: 10px auto;
}

.table tbody tr td {
    font-weight: bold;
}

.table tbody tr.offers td {
    font-weight: normal !important;
    opacity: 0.8;
}

.expired_status {
    color: #fff;
    padding: 0px 3px;
}
#parent .toggle_buttons {
    height: 36px !important;
}

.table thead th {
    vertical-align: top;
    font-size: 13px !important;
}

header#filter_modal___BV_modal_header_ > h4 {
    font-size: 22px;
    color: #000;
    font-weight: bold;
}

th.light_gray {
    text-align: left !important;
}

    .plan-box {
        width: 47%;
    }
    .plan-box h1 {
      margin-top: 0;
    }

    .plan-box span:last-of-type {
        font-size: 15px;
        padding-bottom: 5px;
    }
    .table thead th, .table tbody td {
        border: 0 !important;
    }

    .table-div .table-bordered {
        border: 0 !important;
    }
    th.light_gray.desk {
       display: none;
    }

    td.for-desk {
      display: none;
    }
    .tabs {
    border: 0;
}
.offer-table {
    padding-top: 0 !important;
}

.tabs {
    padding-bottom: 0 !important;
}

.data_grid table.table {
    margin-top: 10px !important;
    border: 0;
}

#kt_content .container-fluid {
    margin-top: -288px;
}

.offer-table .tabs {
    padding-bottom: 0 !important;
}

.data_grid {
    margin-top: 20px !important;
}

div#filter_modal___BV_modal_body_ > .row > div {
    margin-bottom: 18px;
}
.modal {
      z-index: 999999999;
  }
  #kt_content .ddd {
      display: block;
      padding-left: 8px;
    }

    #kt_content .ddd p.last_refreshed {
        display: none;
    }

    #kt_content .ddd .export-btn {
        display: none;
    }

    #kt_content .ddd span {
        display: none;
    }
    #kt_content .ddd button.btn.btn-secondary {
      display: block !important;
      margin-top: 15px;
    }
    div#modal-1___BV_modal_content_ {
      height: 100vh;
      overflow-y: auto;
    }
  .header-mobile-fixed .header-mobile {
      height: 410px !important;
  }

  #kt_content .container-fluid {
      margin-top: -286px;
      position: relative;
    //   z-index: 99;
      width: 100%;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
  }
div#modal-1___BV_modal_outer_ {
    position: static !important;
    height: 100%;
}

div#modal-1___BV_modal_outer_ .modal-dialog.modal-md {
    height: 100%;
    max-width: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    pointer-events: auto !important;
    position: static !important;
}

div#modal-1___BV_modal_outer_ header {
    pointer-events: auto;
}
div#filter_modal___BV_modal_outer_ {
        position: static !important;
        height: 100%;
        width: 100%;
      }

      div#filter_modal___BV_modal_outer_ .modal-dialog.modal-md {
        max-width: 100% !important;
        width: 100% !important;
        height: 100% !important;
        z-index: 9999;
        display: flex;
        margin: 0;
        align-items: flex-end;
      }

      div#filter_modal___BV_modal_outer div#filter_modal___BV_modal_content {
        position: static;
        height: 90%;
        overflow-y: auto;
      }
      div#filter_modal___BV_modal_outer div#filter_modal___BV_modal_content footer {
        border: 0;
      }
      div#filter_modal___BV_modal_content_ {
    position: static;
    height: 90%;
    overflow-y: auto;
}
table.table.table-bordered.referralData {
    width: 100% !important;
}
table.table.table-bordered.affiliateData {
    width: 100% !important;
}

.input-group.btns button.save {
    min-width: 200px !important;
}
.table-div:first-child > table > tbody > tr {
    border-bottom: 15px solid #fff!important;
    background-color: #f4f9ff;
}
.input-group.btns button.save {
    width: 150px !important;
    min-width: auto !important;
}
div#address___BV_modal_outer_ {
    position: static !important;
    height: 100%;
    width: 100%;
}

div#address___BV_modal_outer_ .modal-dialog.modal-md {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 9999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

div#address___BV_modal_outer_ div#address___BV_modal_content_ {
    position: static;
    height: 90% !important;
    overflow-y: auto;
    transform: none !important;
}
div#address___BV_modal_outer_ button.btn.btn-primary {
    width: 150px !important;
    min-width: auto !important;
}
#parent .toggle_buttons {
    width: auto !important;
    margin-right: 30px !important;
}
#kt_content .container-fluid {
    margin-top: -275px !important;
}

.vue-country-select .dropdown-list {
    width: 250px !important;
}
}
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
    opacity: 1 ;
}
div#modal-1___BV_modal_content_ button.close {
    font-size: 40px;
    line-height: normal;
    opacity: 1;
}
.v-btn-toggle--group > .v-btn.v-btn {
    width: auto !important;
}

.vue-country-select {
    border: 0 !important;
    height: 100%;
    border-radius: 0;
}

.vue-country-select .dropdown-arrow {
    display: none !important;
}

.vue-country-select .dropdown {
    border-right: 1px solid #bbb !important;
    padding: 0 !important;
    padding-right: 10px !important;
}

.vue-country-select .country-code {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-left: 10px;
}
 .table-div.userdetails > form.form > .col-md-12 > .row > .col-md-6:first-child > .form-group:nth-last-child(2) > label + div{
    display: flex;
    align-items: center;
    background-color: #F3F6F9;
    border-radius: 0.85rem;
}

.vue-country-select {
    margin-left: 22px;
}

.vue-country-select {
    box-shadow: none !important;
}

.vue-country-select .dropdown-list {
    border: 0;
    top: 185%;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    border-radius: 7px;
    left: -20px !important;
}

.vue-country-select .dropdown-list::-webkit-scrollbar{
  width:10px;
}
.data_grid {
    overflow-y: initial;
}

.input-group.btns {
    justify-content: flex-end;
}
.input-group.btns button {
    border: 0;
    outline: none;
    color: #425466;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 162.02%;
}

.input-group.btns button.save {
    background: #FF6633;
    border-radius: 8px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 14px;
    width: 93px;
    height: 45px;
    min-width: 240px;
    margin-left: 40px;
}
.table-div form.form label {
    color: #718096 !important;
    font-weight: normal !important;
}

table.table.table-bordered.referralData {
    width: 50%;
    margin-top: 30px;
}
table.table.table-bordered.affiliateData {
    width: 50%;
    margin-top: 30px;
}
.offer-table table.table.table-bordered tbody tr {
    border-bottom: 1px solid #ccc;
}

.offer-table table tbody tr.couponClass:first-child{
    border-bottom: 5px solid #FFF2F2 !important;
}

.offer-table table.table.table-bordered tbody tr td:nth-child(2) {
    text-align: right;
    padding-right: 70px !important;
   
}

.offer-table table.table.table-bordered tbody tr td:nth-child(1) {
    color: #718096;
    font-weight: normal;
}
.input-group.btns {
    padding: 0 10px;
}
a.password-eye {
    position: absolute !important;
    right: 25px;
    margin-top: -35px;
    cursor: pointer;
}
div > button.btn.btn-secondary {
    width: 23px;
    height: 22px;
    background: #f3f6f9;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    float: right;
    margin-top: -77px;
    margin-right: 20px;
}

button > i.fa.fa-edit {
    color: #4AAE8C;
}

div#address___BV_modal_outer_ div#address___BV_modal_content_ {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: auto;
    padding: 0 10px;
}

div#address___BV_modal_outer_ button.btn.btn-primary {
    background: #193058;
    border-radius: 8px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 14px;
    width: 93px;
    height: 55px;
    min-width: 200px;
    margin-left: 40px;
    border: 0;
}

div#address___BV_modal_outer_ button.btn.btn-secondary {
    background: transparent;
    border: 0;
}

button.btn.pencil-btn.btn-secondary {
    margin: 0;
    float: none;
    padding: 5px;
    width: 30px;
    height: 30px;
    top: 10px;
    right: 5px;
    position: absolute; 
    background: transparent;
    border: 0;
}

button.btn.pencil-btn.btn-secondary:focus {
    background: transparent;
}

button.btn.pencil-btn.btn-secondary:hover {
    background: transparent !important;
        border: 0;
}
button.btn.pencil-btn.btn-secondary img {
    width: 20px;
    height: 20px;
}

.table-div.userdetails > form.form > .col-md-12 > .row > .col-md-6:first-child > .form-group:last-child > label + div {
    position: relative;
}
.validation-error-msg p {
    position: static !important;
    margin-top: 5px !important;
}
#kt_content > .d-flex.flex-column-fluid {
    position: relative;
    top: 100px;
    margin-bottom: 100px;
}
.table-div.referralDetails + .table-div table tr {
    border: 0 !important;
}

.table-div.referralDetails + .table-div table tr th {
    font-weight: bold;
}

.table-div.referralDetails + .table-div table tr td {
    font-weight: normal;
}

.table-div.referralDetails + .table-div table tr td:first-child {
    font-weight: bold !important;
}
.modal.fade .modal-dialog {
    -webkit-transform: none !important;
    transform: none !important;
}
div#address___BV_modal_outer_ footer {
    padding-top: 0 !important;
}

div#address___BV_modal_outer_ div#address___BV_modal_body_ {
    padding-bottom: 0 !important;
}

div#address___BV_modal_outer_ footer button {
    height: 50px !important;
    border-radius: 15px !important;
}
.data_grid .v-card.v-sheet.theme--light {
    box-shadow: none !important;
}
.offer-table table.table.table-bordered.affiliateData tbody tr td:nth-child(2) {
    text-align: left !important;
}
.offer-table table.table.table-bordered.referralData tbody tr td:nth-child(2) {
    text-align: left !important;
}
.table tbody tr td {
        vertical-align: middle;
}

div#address___BV_modal_outer_ div#address___BV_modal_backdrop_ {
    transition: all 0.15s linear;
}

body.modal-open {
    overflow: initial !important;
}
 .table-div.referralDetails + div.table-div table thead th:nth-child(2) {
    text-align: left !important;
}

div#address___BV_modal_outer_ {
    z-index: 99999 !important;
}

.swal2-container.swal2-center {
    z-index: 999999;
}
.swal2-styled.swal2-confirm {
    color: #FF6633 !important;
}
.request-confirm-class .swal2-confirm.swal2-styled{
    color: #ffffff !important;
}
.request-confirm-class{
    margin-top: 0rem !important;
}
.request-title-class{
    margin-top: 0rem !important;
}
.request-confirm-class .swal2-cancel.swal2-styled{
    padding: 0px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: #ffffff !important;
    position: absolute;
    top: -20px;
    right: -20px;
    margin-left: 15px;
    color: #000 !important;
    border-radius: 15px !important;
    font-size: 15px;
    font-weight: 600;
    width: 15px;
    height: 15px;
}
.alert.alert-success, .alert.alert-primary{
  background-color: #FF6633;
    border-color: #FF6633;
    color: #000;
}
</style>